@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';

:host {
  display: inline-block;
  vertical-align: middle;
  color: $body-font-color;

  .container {
    display: inline-block;
    position: relative;
    min-width: 150px;
    font-size: inherit;
    text-align: left;
  }

  .button {
    @include button();
    outline: 0;
    text-decoration: none;

    &.tiny {
      font-size: map-get($map: $button-sizes, $key: 'tiny');
      padding: 0.15rem 0.25rem;
    }
  }

  .upper {
    display: flex;
    width: 100%;
    cursor: pointer;
    background-color: darken($light-gray, 3%);
    transition: background-color 150ms ease-in-out;

    &:after {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      border-bottom: 1px solid $body-font-color;
      border-left: 1px solid $body-font-color;
      transform: rotate(-45deg) translateY(-50%);
      position: absolute;
      right: 6px;
      top: 50%;
      margin-top: -1px;
    }

    &:hover {
      background-color: darken($light-gray, 7%);
    }
  }

  .bar {
    position: relative;
    flex: 1;
    padding: 0 20px 0 10px;
    display: flex;
    align-items: center;
    font-size: 11px;
  }

  .wrap {
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 3px 0.5em;
    flex: 0 1 auto;
    line-height: 1;
    background-color: $secondary-color;

    span {
      font-weight: 700;
      line-height: 1;
      font-size: 1.2em;
      color: $white;
    }

    &:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-left-color: $secondary-color;
      border-width: 4px;
      margin-top: -4px;
    }
  }
}

:host([expanded]) {
  .upper {
    background-color: darken($light-gray, 7%);
  }
}

:host([compact]) {
  .container {
    min-width: auto;
  }

  .wrap {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .bar>div>span:last-of-type,
  .handle {
    display: none;
  }
}

:host([small]) {
  .container {
    font-size: 11px;
  }

  .bar>div>span:last-of-type {
    font-size: 12px;
  }

  .wrap {
    padding-top: 2px;
    padding-bottom: 2px;

    span {
      font-size: 12px;
    }
  }
}

:host([rating="AAA"]) {
  .wrap {
    background-color: $rating-color-aaa;

    &:after {
      border-left-color: $rating-color-aaa;
    }
  }
}

:host([rating="AA"]) {
  .wrap {
    background-color: $rating-color-aa;

    &:after {
      border-left-color: $rating-color-aa;
    }
  }
}

:host([rating="A"]) {
  .wrap {
    background-color: $rating-color-a;

    &:after {
      border-left-color: $rating-color-a;
    }
  }
}

:host([rating="B"]) {
  .wrap {
    background-color: $rating-color-b;

    &:after {
      border-left-color: $rating-color-b;
    }
  }
}

:host([rating="C"]) {
  .wrap {
    background-color: $rating-color-c;

    &:after {
      border-left-color: $rating-color-c;
    }
  }
}

.wc-company-rating-details {
  display: none;
  position: absolute;
  min-width: 220px;
  z-index: 5;
  top: 100%;
  font-size: 11px;
  line-height: 14px;
  background-color: $light-gray;
  padding: 0.5em;
  border-left: 1px solid darken($light-gray, 7%);
  border-right: 1px solid darken($light-gray, 7%);
  border-bottom: 1px solid darken($light-gray, 7%);

  ul {
    padding: 0;
    margin: 0.5em 0 0;
    list-style: none;
    width: 100%;
    display: flex;

    li {
      text-align: right;

      &:not(:first-of-type) {
        margin-left: 0.5em;
      }
    }
  }

  a {
    padding: 0.35em 0.5em;
  }

  .ratings {
    display: table;

    &>div {
      display: table-row;

      &>div {
        display: table-cell;
        padding-top: 0.25em;
        padding-bottom: 0.25em;

        &:first-child {
          padding-right: 0.5em;
          text-align: center;
          min-width: 3rem;
        }

        &:last-child {
          padding-left: 0.5em;
          white-space: nowrap;
        }
      }

      &:first-of-type {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}